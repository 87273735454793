import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";
import Img from 'gatsby-image';

const RowStyled = styled(Row)`
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-bottom: 105px;
    padding-top: 0;
  }
`;

const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const FuturesHeroContainer = styled(Container)`
  position: relative; 
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 210px;
  box-sizing: border-box;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-top: 285px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 186px;
    padding-right: 186px;
  }
`;

const FuturesTitle = styled.div`
  ${fonts.CircularMedium};
  width: 100%;
  text-align: center;
  color: ${theme.colors.black};
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0;
  position: relative;
  z-index: 11;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 90px;
    line-height: 100.96px;
  }
`;

const IntroContainer = styled.div`
  background: ${theme.colors.black};
  box-sizing: border-box;
  margin: auto -40px auto auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: calc(100% + 40px);
  height: 250px;
  padding: 35px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 415px;
    padding: 70px;
    margin-right: 0;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    margin-right: -186px;
    width: calc(60% + 186px);
  }
`;

const IntroText = styled.div`
  color: ${theme.colors.white};
  ${fonts.MaisonNeueLight};
  max-width: 610px;
  font-size: 16px;
  line-height: 20px;
  a {
    color: ${theme.colors.white};
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: ${theme.colors.grey};
    }
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 22px;
    line-height: 32px; 
  }
`;

const HeroImageContainer = styled.div`
  position: absolute; 
  top: 0;
  left: 0;
  z-index: 0;
  height: 210px;
  width: calc(100% - 105px);
  overflow: hidden;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: calc(100% - 70px);
    width: 50%;
  }
  @media(min-width: 1000px){
    width: 60%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const FuturesHero = ({title, intro, image}) => {
    return (
        <FuturesHeroContainer>
            <RowStyled>
                <ColStyled md={6} lg={4} mdOffset={6} lgOffset={8}>
                    <FuturesTitle>
                        <Fade duration={1000} delay={250}>
                            <div>{title}</div>
                        </Fade>
                    </FuturesTitle>
                </ColStyled>
            </RowStyled>
            <IntroContainer>
              <Overlay className="cursor-white" />
                <Fade duration={750} delay={100}>
                    <IntroText>
                        {intro}
                    </IntroText>
                </Fade>
            </IntroContainer>
            <HeroImageContainer>
                <Fade duration={1000}>
                    <Img fluid={image} alt={"Hero Image"}/>
                </Fade>
            </HeroImageContainer>
        </FuturesHeroContainer>
    )
};

export default FuturesHero;


