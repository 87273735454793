import React, { Component } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import get from 'lodash/get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import styled from 'styled-components';
import FuturesHero from '../components/futures/FuturesHero';
import FuturesJobs from '../components/futures/FuturesJobs';
import theme from '../styles/theme';

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  box-sizing: border-box;
`;

class FuturesPage extends Component {

  render() {
    const path = this.props.location.pathname;
    const jobs = get(this, 'props.data.allContentfulJob.edges');
    const page = get(this, 'props.data.allContentfulFuturesPage.edges')[0].node;

    return (
      <LayoutStyled colorScheme="dark">
        <SEO title={page.title}
          description={documentToPlainTextString(page.intro.json)}
          fullTitle={true}
          path={path} />
        <WhiteBackground>
          <FuturesHero
            title={page.title}
            image={page.heroImage.fluid}
            intro={documentToReactComponents(page.intro.json)} />
          <FuturesJobs jobs={jobs}/>
        </WhiteBackground>
      </LayoutStyled>
    )
  }
}

export default FuturesPage;

export const pageQuery = graphql`
  query FuturesQuery {
    allContentfulFuturesPage(limit: 1) {
      edges {
        node {
          title
          intro {
            json
          }
          heroImage {
            fluid(
              maxWidth: 1920
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulJob(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          title
          slug
          location
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1920
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          image: image {
            fluid(
              maxWidth: 1920
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;


