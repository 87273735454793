import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import PlusLink from '../../resources/images/home_page/plus.svg';
import theme from '../../styles/theme';

const JobsContainer = styled(Container)`
    width: 100%;
    background: ${theme.colors.white};
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 70px;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 140px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 186px;
        padding-right: 186px;
    }
`;

const RowStyled = styled(Row)`
    position: relative;
    padding-bottom: 70px;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-bottom: 175px;
    }
`;

const ColStyled = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const DetailsContainer = styled.div`
    background: ${theme.colors.black};
    height: 250px;
    margin: auto;
    padding-left: 35px;
    width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    @media(min-width: ${theme.breakpoints.medium}) {
        height: 470px;
        padding-left: 15%;
        padding-right: 15%;
        margin-bottom: ${props => props.second && '-35px'};
        margin-top: ${props => props.first && '-35px'};
    }
`;

const JobLocation = styled.div`
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.white};
    font-size: 16px;
    line-height: 19.2px;
    padding-bottom: 15px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 21.6px;
        padding-bottom: 35px;
    }
`;

const JobTitle = styled(Link)`
    ${fonts.CircularMedium};
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.white};
    text-decoration: none;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 32px;
        line-height: 40px;
    }
    &:hover {
      color: ${theme.colors.grey};
    }
`;
const JobImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 250px;
    margin: auto;
    @media(min-width: ${theme.breakpoints.medium}) {
        height: 470px;
        margin-bottom: ${props => props.second && '-35px'};
        margin-top: ${props => props.first && '-35px'};
    }
`;

const JobImg = styled(Img)`
    width: 100%;
    height: 100%;
`;

const PlusImage = styled.div`
    height: 46px;
    width: 46px;
    position: absolute;
    z-index: 11;
    background: url(${PlusLink}) no-repeat;
    background-size: 100%;
    right: 35px;
    bottom: 35px;
    transition: .4s;
    &:hover {
      transform: rotate(360deg);
    }
    @media(min-width: ${theme.breakpoints.medium}) {
        right: 70px;
        bottom: 70px;
    }
`;


const FuturesJobs = ({ jobs } ) => {
    return (
        <JobsContainer>
            {jobs.map((job, index) => {
                if (index % 2 === 0) return (
                    <RowStyled key={index}>
                        <ColStyled md={6}>
                            <DetailsContainer first className="cursor-white">
                                <JobLocation>{job.node.location}</JobLocation>
                                <JobTitle to={`/offer/${job.node.slug}`} className="cursor-dot-white">{job.node.title}</JobTitle>
                            </DetailsContainer>
                        </ColStyled>
                        <ColStyled md={6}>
                            <JobImageContainer second>
                                <JobImg fluid={job.node.image.fluid} alt={job.node.title}/>
                                <Link to={`/offer/${job.node.slug}`} style={{width: '100%'}}>
                                    <PlusImage className="cursor-dot-black"/>
                                </Link>
                            </JobImageContainer>
                        </ColStyled>
                    </RowStyled>
                );
                if (index % 2 === 1) return (
                    <RowStyled key={index}>
                        <ColStyled md={6}>
                            <JobImageContainer first>
                                <JobImg fluid={job.node.image.fluid} alt={job.node.title}/>
                                <Link to={`/offer/${job.node.slug}`}>
                                    <PlusImage className="cursor-dot-black"/>
                                </Link>
                            </JobImageContainer>
                        </ColStyled>
                        <ColStyled md={6}>  
                            <DetailsContainer second className="cursor-white">
                                <JobLocation>{job.node.location}</JobLocation>
                                <JobTitle to={`/offer/${job.node.slug}`} className="cursor-dot-white">{job.node.title}</JobTitle>
                            </DetailsContainer>
                            </ColStyled>
                    </RowStyled>
                )
            })}
        </JobsContainer>
    )
}

export default FuturesJobs;
